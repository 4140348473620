import axios from 'axios'
const { Commons } = require('../../frontend/commons')
//import { ScheduledEmailNotification } from "../models/ScheduledNotification";

axios.interceptors.response.use(
  async function (response) {
    // Check if response contains encrypted data
    if (response.data.encryptedData && response.data.iv) {
      // encryptedData, iv
      // Decrypt the encrypted data
      const decryptedData = await Commons.decryptData(response.data)
      const resData = JSON.parse(decryptedData)
      // Replace encrypted data with decrypted data in response
      response.data = resData
    }
    return response
  },
  function (error) {
    // Handle errors here
    return Promise.reject(error)
  }
)
export var Api = {
  token:
    'M3nuSucc3ssAp1987456321M3nuSucc3ssAp1741258963M3nuSucc3ssAp1963258741',
  //baseUrl: "http://localhost:8080", // LOCAL
  // baseUrl: 'https://imenuappsdev.com', //DEV
  baseUrl: 'https://imenuapps.net', //PROD
  //endPointURL: "http://localhost:7071/api/", //LOCALes1 222
  endPointURL: 'https://imenuappsapi2.azurewebsites.net/api/', //PROD
  // endPointURL: 'https://imenuappsapi2-dev.azurewebsites.net/api/', //DEVELOP
  endPointURLP: 'https://apitest.authorize.net/xml/v1/request.api',

  defaultToken:
    'M3nuSucc3ssAp1987456321M3nuSucc3ssAp1741258963M3nuSucc3ssAp1963258741',

  //  token: 'eposMiddl34PI',
  // endPointURL: 'https://8akf11lds3.execute-api.us-east-1.amazonaws.com/',

  // token: 'Basic UDNQVUFONkw3QkZSSlNSWVE3Sk5NRzNDS1pVT1pSVVg6S0NBSFhUMUlWNFlITEw2Qjc5VDFWUkJDN0tET0VFMDQ=',
  // endPointURL: 'https://api.eposnowhq.com/api/v4/',
  restaurantId: '',
  restaurant: null,
  mergedOrders: [], // Store the merged orders here

  setRestaurantId: async function (restaurantId) {
    this.restaurantId = restaurantId
    if (restaurantId != '') {
      const data = await this.fetchById_Sync('Restaurant', restaurantId)
      this.restaurant = data.data
    } else this.restaurant = null
  },

  getRestaurant: function () {
    return this.restaurant
  },

  setTokenId: function (tokenId) {
    if (tokenId) this.token = tokenId
  },

  fetchAll: async function (entity, params = {}) {
    return await axios.get(this.endPointURL + entity, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId },
      params
    })
  },

  getUrl: async function (entity, url) {
    return await axios.post(
      this.endPointURL + entity,
      { url },
      {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json',
          restaurantid: this.restaurantId
        }
      }
    )
  },

  getUrlTerms: async function (entity, url) {
    return await axios.post(
      this.endPointURL + entity,
      { url },
      {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json'
        }
      }
    )
  },

  fetchAddresses: async function (entity, params = {}) {
    return await axios.get(this.endPointURL + entity, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId },
      params
    })
  },

  fetchWorksheets: async function (params = {}) {
    return await this.fetchAll('worksheet', params)
  },

  fetchTracking: async function (entity, params) {
    return await axios.get(this.endPointURL + entity, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId },
      params: params
    })
  },

  fetchAlls: async function (entity) {
    return await axios.get(this.endPointURL + entity, {
      headers: { Authorization: this.token }
    })
  },

  fetchAddress: async function (entity) {
    try {
      const response = await axios.get(this.endPointURL + entity, {
        headers: { Authorization: this.token }
      })

      return response.data
    } catch (error) {
      console.error('Error en la llamada a la API:', error)
      throw error // Re-lanza el error para que se maneje en el código que llama a fetchAddress
    }
  },

  fetchAllFiltered: async function (entity, data) {
    return await axios.get(this.endPointURL + entity + '?filter=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId },
      params: data
    })
  },

  fetchById: async function (entity, id) {
    return await axios.get(this.endPointURL + entity + '?id=' + id, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  fetchById_Sync: function (entity, id) {
    return axios.get(this.endPointURL + entity + '?id=' + id, {
      headers: {
        Authorization: this.defaultToken,
        restaurantid: this.restaurantId
      }
    })
  },

  postIn: async function (entity, items) {
    console.log('Calling API at:', this.endPointURL + entity, items)
    console.log('Token:', this.token, 'RestaurantId:', this.restaurantId)
    return await axios.post(this.endPointURL + entity, items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  postInBulk: async function (entity, selectedItems) {
    console.log('Calling API at:', this.endPointURL + entity, selectedItems)
    return await axios.post(this.endPointURL + entity, selectedItems, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  post: async function (entity, items) {
    return await axios.post(this.endPointURL + entity, items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },
  postConnect: async function (entity, items) {
    return await axios.post(this.endPointURL + 'connect' + entity, items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  postInDigital: async function (entity, items) {
    return await axios.post(this.endPointURL + entity, items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json'
      }
    })
  },

  login: async function (items) {
    return await axios.post(this.endPointURL + 'login', items, {
      headers: { 'Content-Type': 'application/json' }
    })
  },

  loginForgot: async function (items) {
    return await axios.post(
      this.endPointURL + 'login?forgotPassword=1',
      items,
      { headers: { 'Content-Type': 'application/json' } }
    )
  },

  putIn: async function (entity, items) {
    return await axios.put(this.endPointURL + entity, items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },
  putInAddress: async function (entity, id, items) {
    return await axios.put(this.endPointURL + entity + '/' + id, items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json'
      }
    })
  },
  // putInAddress: async function (entity, items) {
  //   try {
  //     return await axios.put(this.endPointURL + entity, items, {
  //       headers: {
  //         Authorization: this.token,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //   } catch (error) {
  //     throw error;
  //   }
  // },

  putInDigital: async function (entity, items) {
    return await axios.put(this.endPointURL + entity, items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json'
      }
    })
  },

  putInTerms: async function (entity, items) {
    return await axios.put(this.endPointURL + entity, items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json'
      }
    })
  },

  changePassword: async function (items) {
    return await axios.put(this.endPointURL + 'staff?changePassword=1', items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  //delete specific food of data
  deleteFoodFromJournal: async function (entity, journalId, foodIndex) {
    return await axios.delete(
      `${this.endPointURL}${entity}?id=${journalId}&foodIndex=${foodIndex}`,
      {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json'
        }
      }
    )
  },

  deleteById: async function (entity, id) {
    return await axios.delete(this.endPointURL + entity + '?id=' + id, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  deleteByIdAddress: async function (entity, id) {
    return await axios.delete(this.endPointURL + entity + '/' + id, {
      headers: { Authorization: this.token }
    })
  },

  deleteByIdDigital: async function (entity, id) {
    return await axios.delete(this.endPointURL + entity + '?id=' + id, {
      headers: { Authorization: this.token }
    })
  },

  getProductsByCategory: async function (categoryId) {
    return await axios.get(
      this.endPointURL + 'Product?category=' + categoryId,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  findCustomerByEmail: async function (email) {
    return await axios.get(this.endPointURL + 'Customer?email=' + email, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  findProductCharges: async function (id, cant) {
    return await axios.get(
      this.endPointURL + 'othercharges?product=' + id + '&cant=' + cant,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  //Add new function to get all charges on invoices, orders and quotes
  getCharges: async function () {
    return await axios.get(this.endPointURL + 'othercharges', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  getAvailbleShipping: async function () {
    return await axios.get(this.endPointURL + 'shipping?available=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  getAvailbleTax: async function () {
    return await axios.get(this.endPointURL + 'tax?available=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  getAllCategoryActiveMenu: async function () {
    return await axios.get(this.endPointURL + 'menu?category=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  mergeOrders(id) {
    // Make the API request to merge the orders
    // Replace 'apiEndpoint' with your actual API endpoint
    axios
      .get(this.endPointURL + 'order', {
        params: {
          clientId: id // Replace with the actual client ID
        }
      })
      .then((response) => {
        this.mergedOrders = response.data // Store the merged orders in the data property
      })
      .catch((error) => {
        console.error(error)
      })
  },

  mergeOrder: async function (id) {
    return await axios.get(this.endPointURL + 'order?customer=' + id, {
      headers: { Authorization: this.token }
    })
  },

  getCustomerOrders: async function (id) {
    //console.log('JQD', "getCustomerOrders", id, this.token);
    return await axios.get(this.endPointURL + 'order?customer=' + id, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  /**
   *
   * @param {String} id order Id
   * @returns details of the order
   */
  getOrderById: async function (id) {
    const headers = {
      Authorization: this.token
    }

    return await axios.get(this.endPointURL + 'order?id=' + id, { headers })
  },

  getOrdersAll: async function (id) {
    const headers = {
      Authorization: this.token
    }

    return await axios.get(this.endPointURL + 'order?customer=' + id, {
      headers
    })
  },

  getCustomerOrdersAll: async function (customerId) {
    try {
      // Retrieve all registered restaurants for the client
      const restaurants = await axios.get(
        this.endPointURL + 'restaurants?customer=' + customerId,
        {
          headers: { Authorization: this.token }
        }
      )

      // Fetch orders for each restaurant and merge them
      const allOrders = await Promise.all(
        restaurants.data.map(async (restaurant) => {
          const response = await axios.get(
            this.endPointURL + 'order?customer=' + customerId,
            {
              headers: {
                Authorization: this.token,
                restaurantid: restaurant.restaurantId
              }
            }
          )
          //console.log('Response from getCustomerOrders:', response.data);
          return response.data
        })
      )

      // Flatten the array of orders from different restaurants
      return allOrders.flat()
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  getCustomerTickets: async function (id) {
    return await axios.get(this.endPointURL + 'order?ticket=' + id, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  sendEmail: async function (items) {
    //map these to the new
    const scheduledEmail = {
      email: items.email,
      subject: items.subject,
      body: items.mess,
      scheduledAt: new Date().getTime() + 5000 //this is almost realtime with allowance of 5seconds
    }

    return this.scheduleEmailNotification(scheduledEmail)

    /*
    return await axios.post(this.endPointURL + "order?email=1", items, {
      headers: {
        Authorization: this.token,
        "Content-Type": "application/json",
        restaurantid: this.restaurantId,
      },
    });
    */
  },

  sendSms: async function (items) {
    return await axios.post(this.endPointURL + 'order?sms=1', items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  menuSinCaterin: async function () {
    return await axios.get(this.endPointURL + 'menu?sinCatering=1', {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  menuConCaterin: async function () {
    return await axios.get(this.endPointURL + 'menu?concatering=1', {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  // categoryByMenuId: async function (id) {
  //   return await axios.get(this.endPointURL + 'menu?categorybymenu=' + id, {
  //     headers: { Authorization: this.token, restaurantid: this.restaurantId }
  //   })
  // },
  
  categoryByMenuId: async function (ids) {
    // Convierte el array de IDs a una cadena de consulta adecuada
    const idsParam = ids.join(',');    
    return await axios.get(this.endPointURL + 'menu?categorybymenu=' + idsParam, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    });
  },
  
  savePickupRequest: async function (assetPickupObject) {
    console.log("Data sent::::",assetPickupObject);
    
    return await axios.post(this.endPointURL + 'assetpickup', assetPickupObject, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  saveEditPickupRequest: async function (assetPickupObject) {
    return await axios.put(this.endPointURL + 'assetpickup', assetPickupObject, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  hasPermission: function (user, permission) {
    let res = false
    for (let index = 0; index < user.Roles.length; index++) {
      this.fetchById_Sync('Rol', user.Roles[index])
        .then((response) => {
          switch (permission) {
            case 'canViewMenu':
              res = response.data.canViewMenu
              break
            case 'canCreateMenu':
              res = response.data.canCreateMenu
              break
            case 'canEditMenu':
              res = response.data.canEditMenu
              break
            case 'canDeleteMenu':
              res = response.data.canDeleteMenu
              break
            case 'canCreateCategory':
              res = response.data.canCreateCategory
              break
            case 'canEditCategory':
              res = response.data.canEditCategory
              break
            case 'canDeleteCategory':
              res = response.data.canDeleteCategory
              break
            case 'canCreateProduct':
              res = response.data.canCreateProduct
              break
            case 'canEditProduct':
              res = response.data.canEditProduct
              break
            case 'canDeleteProduct':
              res = response.data.canDeleteProduct
              break
            case 'canCreateCustomer':
              res = response.data.canCreateCustomer
              break
            case 'canEditCustomer':
              res = response.data.canEditCustomer
              break
            case 'canDeleteCustomer':
              res = response.data.canDeleteCustomer
              break
            case 'canCreateTable':
              res = response.data.canCreateTable
              break
            case 'canEditTable':
              res = response.data.canEditTable
              break
            case 'canDeleteTable':
              res = response.data.canDeleteTable
              break
            case 'canCreateTax':
              res = response.data.canCreateTax
              break
            case 'canEditTax':
              res = response.data.canEditTax
              break
            case 'canDeleteTax':
              res = response.data.canDeleteTax
              break
            case 'canCreateShipping':
              res = response.data.canCreateShipping
              break
            case 'canEditShipping':
              res = response.data.canEditShipping
              break
            case 'canDeleteShipping':
              res = response.data.canDeleteShipping
              break
            case 'canCreateOtherCharge':
              res = response.data.canCreateOtherCharge
              break
            case 'canEditOtherCharge':
              res = response.data.canEditOtherCharge
              break
            case 'canDeleteOtherCharge':
              res = response.data.canDeleteOtherCharge
              break
            case 'canCreateUser':
              res = response.data.canCreateUser
              break
            case 'canEditUser':
              res = response.data.canEditUser
              break
            case 'canDeleteUser':
              res = response.data.canDeleteUser
              break
            case 'canCreateRole':
              res = response.data.canCreateRole
              break
            case 'canEditRole':
              res = response.data.canEditRole
              break
            case 'canDeleteRole':
              res = response.data.canDeleteRole
              break
            case 'canEditOrder':
              res = response.data.canEditOrder
              break
            default:
              break
          }
          if (res) {
            return res
          }
        })
        .catch((e) => {
          e
        })
    }
    return res
  },

  getAllContacts: async function () {
    //remove this url when api deployed.
    // var endPointURL = "http://10.110.10.201:5000/api/";
    const response = await axios.get(this.endPointURL + 'contact', {
      headers: {
        Authorization: this.token,
        restaurantid: this.restaurantId
      }
    })
    return response.data
  },

  createContact: async function (contact) {
    //remove this url when api deployed.
    var endPointURL = 'http://10.110.10.201:5000/api/'
    const response = await axios.post(this.endPointURL + 'contact', contact, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
    return response.data
  },

  updateContact: async function (contact) {
    //remove this url when api deployed.
    var endPointURL = 'http://10.110.10.201:5000/api/'
    const response = await axios.put(this.endPointURL + 'contact', contact, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
    return response.data
  },

  deleteContact: async function (id) {
    //remove this url when api deployed.
    var endPointURL = 'http://10.110.10.201:5000/api/'
    const response = await axios.delete(this.endPointURL + 'contact?id=' + id, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
    return response.data
  },

  /**
   * Get reservations from the current restaurant
   * @param {} id
   * @returns
   */
  getReservationsFromRestaurant: async function () {
    return await axios.get(this.endPointURL + 'reservation', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  getCustomerReservations: async function (id) {
    return await axios.get(this.endPointURL + 'reservation?customer=' + id, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },
  getReservationByCode: async function (id) {
    return await axios.get(this.endPointURL + 'reservation?code=' + id, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  getReservationsByOrderId: async function (orderId) {
    return await axios.get(
      this.endPointURL + 'reservation?orderId=' + orderId,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  customerLogIn: async function (item) {
    return await axios.post(this.endPointURL + 'customer?login=1', item, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  customerForgotPassword: async function (item) {
    return await axios.post(
      this.endPointURL + 'customer?forgotPassword=1',
      item,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  customerResetPassword: async function (item) {
    return await axios.put(
      this.endPointURL + 'customer?changePassword=1',
      item,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  customerSoftCreate: async function (items) {
    return await axios.post(this.endPointURL + 'customer?sc=1', items, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
        restaurantid: this.restaurantId
      }
    })
  },

  //Doing payments

  // payACH: async function(item){
  //     console.log(this.endPointURLP);
  //     return await axios.post(this.endPointURLP , item, {headers: {'Authorization':this.token, 'Content-Type': 'application/json', 'restaurantid': this.restaurantId}})
  // },

  // payACH: async function(item){
  //     return await axios.post(this.endPointURLP, item, {headers: {'Authorization': this.token, 'restaurantid': this.restaurantId}})
  // },

  authenticateShift4: async function (item) {
    return await axios.post(
      this.endPointURL + 'payment?activateshift4=1',
      item,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  payShift4: async function (item, restaurantId, ipClient, isDelivery) {
    return await axios.post(this.endPointURL + 'payment?payshift4=1', item, {
      headers: {
        Authorization: this.token,
        restaurantid: restaurantId,
        ipClient: ipClient,
        isDelivery: isDelivery
      }
    })
  },

  authorizeShift4: async function (item, moto, restaurantId, ipClient) {
    return await axios.post(
      this.endPointURL + 'payment?authorizationshift4=' + moto,
      item,
      {
        headers: {
          Authorization: this.token,
          restaurantid: restaurantId,
          ipClient: ipClient
        }
      }
    )
  },

  captureShift4: async function (invoice, moto, restaurantId) {
    return await axios.get(
      this.endPointURL + 'payment?captureshift4=' + invoice + '&moto=' + moto,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  refoundShift4: async function (item, moto, restaurantId, isDelivery) {
    return await axios.post(
      this.endPointURL + 'payment?refoundshift4=' + moto,
      item,
      {
        headers: {
          Authorization: this.token,
          restaurantid: restaurantId,
          isDelivery: isDelivery
        }
      }
    )
  },

  invoiceInformationShift4: async function (
    invoice,
    moto,
    restaurantId,
    isDelivery
  ) {
    return await axios.get(
      this.endPointURL + 'payment?invoice=' + invoice + '&moto=' + moto,
      {
        headers: {
          Authorization: this.token,
          restaurantid: restaurantId,
          isDelivery: isDelivery
        }
      }
    )
  },

  voidShift4: async function (invoice, moto, restaurantId, isDelivery) {
    return await axios.delete(
      this.endPointURL + 'payment?invoice=' + invoice + '&moto=' + moto,
      {
        headers: {
          Authorization: this.token,
          restaurantid: restaurantId,
          isDelivery: isDelivery
        }
      }
    )
  },

  getInvoiceSequence: async function (restaurantId) {
    if (restaurantId == null) restaurantId = this.restaurantId
    return await axios.get(this.endPointURL + 'imenusupport?invoicenumber=1', {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  getQuoteSequence: async function (restaurantId) {
    if (restaurantId == null) restaurantId = this.restaurantId
    return await axios.get(this.endPointURL + 'imenusupport?quotenumber=1', {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  getCaptchaKey: async function (restaurantId) {
    if (restaurantId == null) restaurantId = this.restaurantId
    return await axios.get(this.endPointURL + 'imenusupport?captchakey=1', {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  getUtc: async function () {
    return await axios.get(this.endPointURL + 'login?getutc=1', {
      headers: { Authorization: this.token }
    })
  },

  payAutorizeNet: async function (item, restaurantId) {
    return await axios.post(
      this.endPointURL + 'payment?payAutorizeNet=1',
      item,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  payACH: async function (item, restaurantId) {
    return await axios.post(this.endPointURL + 'payment?payACH=1', item, {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  authorizeACH: async function (item, restaurantId) {
    return await axios.post(this.endPointURL + 'payment?authorizeACH=1', item, {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  refoundAutorizeNet: async function (item, restaurantId) {
    return await axios.post(
      this.endPointURL + 'payment?refoundAutorizeNet=1',
      item,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  getClientIp: async function () {
    //http://www.ip-api.com/json/
    return await axios.get('https://api.ipify.org/?format=json')
  },

  payQrShift4: async function (item, restaurantId) {
    return await axios.post(this.endPointURL + 'payment?payqrshift4=1', item, {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  validateStatusQrShift4: async function (item, restaurantId) {
    return await axios.post(
      this.endPointURL + 'payment?statusqrshift4=1',
      item,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  cancelQrShift4: async function (item, restaurantId) {
    return await axios.post(
      this.endPointURL + 'payment?cancelqrshift4=1',
      item,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  generateKeyTsys: async function (item, restaurantId) {
    return await axios.post(
      this.endPointURL + 'payment?generateKeyTsys=1',
      item,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  tokenCardTsys: async function (item, restaurantId) {
    return await axios.post(
      this.endPointURL + 'payment?generateTokenTsys=1',
      item,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  payTsys: async function (item, restaurantId) {
    return await axios.post(this.endPointURL + 'payment?payTsys=1', item, {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  authorizeTsys: async function (item, restaurantId) {
    return await axios.post(
      this.endPointURL + 'payment?authorizeTsys=1',
      item,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  captureTsys: async function (item, restaurantId) {
    return await axios.post(this.endPointURL + 'payment?captureTsys=1', item, {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  invoiceTsys: async function (invoice, restaurantId) {
    return await axios.get(
      this.endPointURL + 'payment?invoiceTsys=' + invoice,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  returnWithReferenceTsys: async function (item, restaurantId) {
    return await axios.post(
      this.endPointURL + 'payment?returnWithReferenceTsys=1',
      item,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  voidTsys: async function (item, restaurantId) {
    return await axios.post(this.endPointURL + 'payment?voidTsys=1', item, {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  restoreBackUpFromPortal: async function () {
    return await axios.get(this.endPointURL + 'setting?restorefromportal=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  restoreBackUpFromFile: async function (item) {
    return await axios.post(
      this.endPointURL + 'setting?restorefromfile=1',
      item,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  backUpToPortal: async function () {
    return await axios.get(this.endPointURL + 'setting?backup=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  initPaxStore: async function () {
    return await axios.get(this.endPointURL + 'payment?initPaxstoreDevice=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  doCreditStore: async function () {
    return await axios.get(
      this.endPointURL + 'payment?doCreditPaxstoreDevice=1',
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  walletInformation: async function (item, restaurantId, ipClient) {
    return await axios.post(this.endPointURL + 'payment?walletShift4=1', item, {
      headers: {
        Authorization: this.token,
        restaurantid: restaurantId,
        ipClient: ipClient
      }
    })
  },

  getPaymentByInvoice: async function (invoice, restaurantId) {
    return await axios.get(
      this.endPointURL + 'allpayments?invoice=' + invoice,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  getPaymentByOrder: async function (modelId, restaurantId) {
    return await axios.get(
      this.endPointURL + 'allpayments?modelid=' + modelId,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  getPaymentByDateTo: async function (todate, restaurantId) {
    return await axios.get(this.endPointURL + 'allpayments?todate=' + todate, {
      headers: { Authorization: this.token, restaurantid: restaurantId }
    })
  },

  getPaymentByDateToFrom: async function (datefrom, dateto, restaurantId) {
    return await axios.get(
      this.endPointURL +
        'allpayments?rangedatefrom=' +
        datefrom +
        '&rangedateto=' +
        dateto,
      { headers: { Authorization: this.token, restaurantid: restaurantId } }
    )
  },

  newRestaurant: async function (data) {
    return await axios.post(
      this.endPointURL + 'imenusupport?newRestaurant=1',
      data,
      { headers: { Authorization: this.defaultToken } }
    )
  },

  deleteRestaurant: async function (id) {
    return await axios.delete(
      this.endPointURL + 'imenusupport?deleteRestaurant=' + id,
      { headers: { Authorization: this.defaultToken } }
    )
  },

  unsubscribe: async function (restId, data) {
    return await axios.put(this.endPointURL + 'login?unsubscribe=1', data, {
      headers: { Authorization: this.token, restaurantid: restId }
    })
  },

  testRestaurantEmail: async function (data) {
    return await axios.post(this.endPointURL + 'setting?testEmail=1', data, {
      headers: {
        Authorization: this.defaultToken,
        restaurantid: this.restaurantId
      }
    })
  },

  testSupportEmail: async function (data) {
    return await axios.post(
      this.endPointURL + 'imenusupport?testEmail=1',
      data,
      { headers: { Authorization: this.token } }
    )
  },

  payPayFabric: async function (data) {
    return await axios.post(this.endPointURL + 'payment?payPayFabric=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  authorizePayFabric: async function (data) {
    return await axios.post(
      this.endPointURL + 'payment?authorizePayFabric=1',
      data,
      {
        headers: {
          Authorization: this.defaultToken,
          restaurantid: this.restaurantId
        }
      }
    )
  },

  processPayFabric: async function (data) {
    return await axios.post(
      this.endPointURL + 'payment?processPayFabric=1',
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  payNexgo: async function (data) {
    return await axios.post(this.endPointURL + 'payment?payNexgo=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  verifyNexgo: async function (data) {
    return await axios.post(this.endPointURL + 'payment?verifyNexgo=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  voidNexgo: async function (data) {
    return await axios.post(this.endPointURL + 'payment?voidNexgo=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  returnNexgo: async function (data) {
    return await axios.post(this.endPointURL + 'payment?returnNexgo=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  authorizeNexgo: async function (data) {
    return await axios.post(
      this.endPointURL + 'payment?authorizeNexgo=1',
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  captureNexgo: async function (data) {
    return await axios.post(this.endPointURL + 'payment?captureNexgo=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  tipNexgo: async function (data) {
    return await axios.post(this.endPointURL + 'payment?tipNexgo=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  batchCloseNexgo: async function (data) {
    return await axios.post(
      this.endPointURL + 'payment?batchCloseNexgo=1',
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  payNAB: async function (data) {
    return await axios.post(this.endPointURL + 'payment?payNAB=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  authorizeNAB: async function (data) {
    return await axios.post(this.endPointURL + 'payment?authorizeNAB=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  incrementAuthorizeNAB: async function (data, invoice, moto) {
    return await axios.post(
      this.endPointURL +
        'payment?incrementAuthorizeNAB=' +
        invoice +
        '&moto=' +
        moto,
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  captureNAB: async function (data, invoice, moto) {
    return await axios.post(
      this.endPointURL + 'payment?captureNAB=' + invoice + '&moto=' + moto,
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  refundNAB: async function (data, invoice, moto) {
    return await axios.post(
      this.endPointURL + 'payment?refundNAB=' + invoice + '&moto=' + moto,
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  voidNAB: async function (data, invoice, moto) {
    return await axios.post(
      this.endPointURL + 'payment?voidNAB=' + invoice + '&moto=' + moto,
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  getInvoiceNAB: async function (invoice) {
    return await axios.get(
      this.endPointURL + 'payment?getInvoiceNAB=' + invoice,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  getServerDate: async function () {
    return await axios.get(this.endPointURL + 'imenusupport?getServerHour=1', {
      headers: { Authorization: this.token }
    })
  },

  getAllCustomerCredit: async function (customerId) {
    return await axios.get(
      this.endPointURL + 'customercredit?customer=' + customerId,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  getActiveCustomerCredit: async function (customerId) {
    return await axios.get(
      this.endPointURL + 'customercredit?customerActive=' + customerId,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  getRestaurantCustomer: async function () {
    return await axios.get(this.endPointURL + 'order?allCustomer=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },
  customerCreditForAll: async function (data) {
    return await axios.post(
      this.endPointURL + 'customercredit?createForAll=1',
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  thisDateSheetHour: async function (data) {
    return await axios.get(this.endPointURL + 'worksheet?thisdate=' + data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },
  findCompleteRestaurantObject: async function () {
    const resp = await axios.get(
      this.endPointURL + 'restaurant?findCompleteObject=' + this.restaurantId,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
    if (resp.status === 200) {
      return resp.data
    } else {
      return null
    }
  },

  findRestaurantToFront: async function () {
    const resp = await axios.get(
      this.endPointURL +
        `restaurant?findRestaurantToFront=${this.restaurantId}`,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
    if (resp.status === 200) {
      return resp.data
    } else {
      return null
    }
  },

  findCustomerInfo: async function (customerId) {
    const resp = await axios.get(
      this.endPointURL +
        `restaurant?findCustomerInfo=${this.restaurantId}&customerId=${customerId}`,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
    if (resp.status === 200) {
      return resp.data
    } else {
      return null
    }
  },

  generateTokenDoorDash: async function (data) {
    return await axios.post(
      this.endPointURL + `payment?activateDoordash=1`,
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  exportData: async function (entity, fields) {
    return await axios.get(this.endPointURL + 'data', {
      params: { fields, entity },
      responseType: 'blob',
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  importData: async function (entity, formData) {
    return await axios.post(this.endPointURL + 'data', formData, {
      params: { entity },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: this.token,
        restaurantid: this.restaurantId
      }
    })
  },

  getCustomersByRestaurant: async function () {
    return await axios.get(
      this.endPointURL + 'Customer?getByRestaurant=' + this.restaurantId,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  getProductsByRestaurant: async function () {
    return await axios.get(
      this.endPointURL + 'Product?getByRestaurant=' + this.restaurantId,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  getReportsData: async function (module, type, params) {
    try {
      // const customer = filters.customer ? `&customer=${filters.customer}` : "";
      // const product = filters.product ? `&product=${filters.product}` : "";
      // const dateFrom = filters.dateFrom ? `&dateFrom=${filters.dateFrom}` : "";
      // const dateTo = filters.dateTo ? `&dateTo=${filters.dateTo}` : "";
      return await axios.get(
        this.endPointURL + `report?module=${module}&type=${type}`,
        {
          params: params,
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  getProductCategories: async function () {
    return await axios.get(this.endPointURL + 'report?getProductCategories=1', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  ACHPayNAB: async function (data) {
    return await axios.post(this.endPointURL + 'payment?ACHPayNAB=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  ACHVoidNAB: async function (data) {
    return await axios.post(this.endPointURL + 'payment?ACHVoidNAB=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  ACHRefundNAB: async function (data) {
    return await axios.post(this.endPointURL + 'payment?ACHRefundNAB=1', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  /**
   * Scheduled notification
   */
  scheduleEmailNotification: async function (scheduleEmailNotification) {
    //ScheduledEmailNotification
    const data = {
      ...scheduleEmailNotification,
      method: 'email',
      userId: '123' //temporarily use this
    }
    return await axios.post(this.endPointURL + 'scheduledNotification', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  /**
   * Subscription Payment
   */
  createSubscriptionPayment: async function (subscriptionPayment) {
    const data = {
      ...subscriptionPayment,
      merchantId: this.restaurantId,
      userId: '111' // temporarily use this
    }

    return await axios.post(this.endPointURL + 'subscription', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },

  cancelScheduledEmailNotification: async function (
    scheduleEmailNotificationId,
    userId
  ) {
    //ScheduledEmailNotification
    const data = {
      status: 'cancelled',
      userId
    }
    return await axios.put(
      this.endPointURL +
        `scheduledNotification/${scheduleEmailNotificationId}/cancel`,
      data,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  /**
   * Get all addresses by the user
   */
  getAddressByUser: async function (userId, type) {
    if (type) {
      type = `&type=${type}`
    } else {
      type = ''
    }
    return await axios.get(
      this.endPointURL + `address?customerId=${userId}${type}`,
      {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      }
    )
  },

  // getJournalByUser: async function (userId) {
  //   return await axios.get(
  //     this.endPointURL + `journalentry?customerId=${userId}`,
  //     {
  //       headers: { Authorization: this.token},
  //     }
  //   );
  // },
  getJournalByUser: async function () {
    return await axios.get(this.endPointURL + 'journalentry', {
      headers: { Authorization: this.token }
    })
  },

  /**
   * Set address as a default
   */
  setAddressAsDefault: async function (addressId) {
    return await axios.put(
      this.endPointURL + `address/${addressId}/makeDefault`,
      {},
      {
        headers: { Authorization: this.token }
      }
    )
  },

  //pickup requests api
  // fetchAllPickupRequests: async function () {
  //   return await axios.get(
  //     this.endPointURL + 'assetpickup',
  //     {},
  //     {
  //       headers: { Authorization: this.token, restaurantid: this.restaurantId }
  //     }
  //   )
  // }
  fetchAllPickupRequests: async function () {
    return await axios.get(this.endPointURL + 'assetpickup', {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },
}
